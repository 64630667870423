.wrapper {
  position: relative;
  width: 100%;
  max-width: 49rem;
  padding: 2.25rem 1.625rem 1.875rem 4.5rem;
  background-color: #ffffff;
  border-radius: 1rem;
}

.titleWrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.logoTitle {
  width: 1.75rem;
  height: auto;

  & path {
    fill: #F8C135;
  }
}

.title {
  margin-left: 0.625rem;

  text-transform: unset;
}

.text {
  font-size: 0.75rem;
  line-height: 1.166;
  letter-spacing: 0.2px;
  max-width: 35.625rem;
  margin-bottom: 2.125rem;
}

.closeBtn {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;

  cursor: pointer;
  background-color: transparent;
  border: none;

}

.submitBtn {

  width: 12rem;
  margin-top: 2.875rem;
}

.authSmsBtn {
  letter-spacing: 0.2px;
  background-color: transparent;
  border: none;
  color: white;
  margin-left: 2rem;
  cursor: pointer;
}

@media(max-width: "768px") {
  .wrapper {
    padding: 2rem 1.5rem;
  }

  .submitBtn {
    margin-top: 1.75rem;
  }

  .authSmsBtn {
    display: flex;
    margin-top: 1rem;
    margin-left: 0;
  }
}