.optionalCard {
  position: relative;
  margin-top: 0.3rem;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;
  border-top: 0.0625rem solid rgba(68, 68, 68, 0.4);
}

.imageWrap {
  margin-right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.125rem;
}

.image {
  width: 100%;
  height: 100%;
  font-size: 0.375rem;
  object-fit: contain;
  border-radius: 0.125rem;
}

.infoBlock {
  flex-grow: 1;
}

.insideItem {
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: space-between;
  font-size: 0.625rem;
  line-height: 1.2;
}

.title {
  margin-bottom: 0.5rem;
  display: inline-block;
  font-size: 0.625rem;
  font-weight: 900;
  text-transform: uppercase;
}

.text {
  font-size: 0.75rem;
}

.description {
  padding-right: 0.625rem;
  max-width: 20rem;
}

.content {
  margin-right: auto;
  width: 9.375rem;
}

.count {
  margin-right: 1.5rem;
  text-align: right;
  font-size: 0.625rem;
  font-weight: 700;
}

.countBtn {
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  border: none;
  color: white;
  cursor: pointer;
}

.costWrap {
  padding-left: 1.25rem;
  width: 8.25rem;
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.cost {
  font-size: 0.625rem;
  font-weight: 700;
}

.checkbox_group {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 1.875rem;
}

.label {
  padding-right: 1.375rem;
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 1.286;
  letter-spacing: -0.5px;
  cursor: pointer;

  &::after {
    top: 50%;
    left: auto;
    right: 0;
    transform: translateY(-50%);
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 0.125rem;
    border: 1px solid white;
  }

  &::before {
    top: 50%;
    left: auto;
    right: 3px;
    transform: translateY(-50%);
    display: none;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.125rem;
    background: linear-gradient(180deg, #25b444 0%, #00ff19 100%);
  }

  & a {
    color: white;
    display: inline-block;
    text-decoration: underline;
  }
}

input[type='checkbox']:checked + .label {
  &::before {
    display: block;
  }
}

// SELECT SCSS
.selector {
  position: relative;

  &Error {
    > :global(div.optCard-select__control) {
      border-color: #ce1616;
    }
  }
}

.messageError {
  display: none;
}

@media (max-width: 1440px) {
  .costWrap {
    width: 22%;
  }
}

@media (max-width: 768px) {
  .imageWrap {
    margin-bottom: 1rem;
  }
  .insideItem {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(6rem, 1fr));
  }

  .content {
    width: 100%;
  }

  .count {
    max-width: 3rem;
  }

  .costWrap {
    padding-left: 0;
    border: none;
  }
}

// SELECT SCSS
.selector {
  position: relative;

  &Error {
    > :global(div.optCard-select__control) {
      border-color: #ce1616;
    }
  }
}

.messageError {
  display: none;
}
