.Card {
  margin-bottom: 1rem;
  padding: 1.25rem 1.5rem;
  display: flex;
  color: black;
  background-color: white;
  border-radius: 0.875rem;
  box-shadow: 0 0.25rem 1rem rgba(26, 16, 94, 0.25);
}

.column {
  margin-right: 1rem;
  padding-right: 1rem;
  width: 80%;
  /*   border-right: 0.0625rem solid #e6f3f9; */
}

.title {
  margin-bottom: 0.25rem;
  font-size: 1rem;
}

.text {
  margin-bottom: 0.5rem;
}

.ResultCard .column {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 12px;
}
@media (max-width: 500px) {
  .textWrap {
    overflow: auto;
  }
}
