.wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input,
.select,
.textarea {
  font-size: 14px;
  padding: 0.75rem 1rem;
  border-radius: 0.625rem;
  outline: none;
  box-shadow: none;
  border: 1px solid #3d3d3d;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.error {
  border: 1px solid red;
}

.textarea {
  resize: none;
}

.textareaLabel,
.labelFile,
.labelFileBasket {
  margin-bottom: 0.5rem;
}

.labelFile {
  background-color: #dadada;
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;

  & span {
    display: block;
    margin-bottom: 1rem;
  }
}
.labelFileBasket {
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;

  & span {
    display: block;
    margin-bottom: 1rem;
  }
}
.checkboxWrap {
  display: flex;
  align-items: center;

  & a {
    color: black;
  }
}

.serf {
  position: relative;
  color: #c86363;
  font-size: 0.625rem;
  line-height: 1.2;
  max-width: 15rem;
  margin-top: 0.5rem;
}

.costWrap {
  white-space: nowrap;
  font-size: 0.7rem;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  margin-bottom: 5px;
}

.labelCheckbox {
  cursor: pointer;
  position: relative;
  padding-left: 2rem;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
  }

  &::after {
    width: 1.5rem;
    height: 1.5rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px solid grey;
    border-radius: 0.3125rem;
  }

  &::before {
    display: none;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 0.75rem;
    background-color: #db4c4c;
    border-radius: 0.3125rem;
    transform: translate(-50%, -50%);
  }
}

.checkbox:checked + .labelCheckbox {
  &::before {
    display: block;
  }
}

@media (max-width: 768px) {
  .input {
    width: 100%;
  }

  .input,
  .select,
  .textarea {
    font-size: 12px;
  }
}

.item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1.875rem 1.25rem 2.5rem;
  border-bottom: 0.0625rem solid black;
}

.inputFile {
  display: none;
}

.labelFile,
.labelFileBasket {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.filter {
  font-size: 0.75rem;
  font-weight: 400;
  display: block;
  margin-bottom: 1rem;
}

.messageError {
  position: absolute;
  bottom: -1.25rem;
  left: 0;
  letter-spacing: 0.2px;
}

.selector {
  position: relative;

  &Error {
    > :global(div.ticket-select__control) {
      border-color: #855757;
    }

    > :global(div.cluster-select__control) {
      border-color: #855757;
    }
  }

  &Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.textFile {
  display: block;
  border: 1px solid #323232;
  border-radius: 1rem;
  text-align: center;
  white-space: nowrap;
  min-width: 294px;
  max-width: 23.75rem;

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;

  padding: 1rem 3.75rem;
  max-height: 3.125rem;

  color: #323232;
}

.cluster {
  max-width: 22.75rem;
  min-width: 19.75rem;
}

.notes {
  column-gap: 4px;
  display: list-item;
}

.notesWrapper {
  list-style-type: disc;
  margin-left: 1rem;
}

.registered {
  color: #fff;
  opacity: 1;
}

.labelFileError {
  border-color: #855757;
}

.logoWrap {
  margin-bottom: auto;
  width: 6rem;
  flex-shrink: 0;
  margin-right: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.infoBlock {
  flex-grow: 1;
  padding-left: 1.5rem;
  border-left: 1px solid black;
}

.row {
  display: flex;
  margin-top: 30px;
  gap: 30px;
}

//////////// INSIDE ITEM
.insideItem {
  display: flex;
  font-size: 0.625rem;
  line-height: 1.2;

  & .count {
    margin-bottom: 1rem;
  }
}

.insideItemInsurance {
  padding-bottom: 0.4rem;
}

.insideItemAdd {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.location {
  width: 6rem;
  margin-right: auto;
}

.locationInsurance {
  margin-right: auto;
}

.insideItem > * {
  padding-top: 1.875rem;
}

.insideItem:last-child > * {
  padding-bottom: 1.25rem;
}

.format {
  margin-right: auto;
  width: 5.5rem;
}

.count {
  margin-right: 1.5rem;
  text-align: right;
}

.countBtn {
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  border: none;
  color: black;
  cursor: pointer;
}

.costWrapC {
  padding-left: 1.25rem;
  width: 8.25rem;
  border-left: 1px solid black;
}

.text {
  margin-top: 0.75rem;
  position: relative;
}

.countText {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    width: 20%;
    text-align: center;
  }
}

.cost {
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: 700;
}

.totalCost {
  font-size: 1rem;
}

.checkbox {
  display: none;
}

.checkbox:checked + .labelCheck {
  &::before {
    display: block;
  }
}

.labelCheck {
  position: relative;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.5px;

  padding-left: 2.125rem;
  max-width: 12.625rem;

  &::after {
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 0.3125rem;
    border: 1px solid #323232;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    display: none;
    width: 0.875rem;
    height: 0.875rem;
    top: 50%;
    transform: translateY(-50%);
    left: 0.35rem;
    border-radius: 0.25rem;
    background: linear-gradient(180deg, #25b444 0%, #00ff19 100%);
  }
}

.ticketForm {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.formText {
}

.formUpdateText {
  margin-top: 15px;
  color: #dc3737;
}

.formWrap {
  margin-top: 1.125rem;
}

.radioWrapper {
  position: relative;
}

.genderTitle {
}

.genderList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.message {
  position: absolute;
  bottom: -1.25rem;
  font-size: 0.75rem;
  line-height: 1.125;
  letter-spacing: 0.2px;
}

.genderItem {
  letter-spacing: 0.2px;

  &:not(:last-child) {
    margin-right: 4rem;
  }
}

.addBlock {
  display: grid;
  gap: 1.875rem;
  grid-template-columns: 56% 40%;
  align-items: center;
  margin-top: 1.625rem;
  padding-right: 2rem;
}

.deleteItemBtn {
  position: absolute;
  padding-right: 1rem;
  bottom: 3.25rem;
  right: 0;
  cursor: pointer;
  font-size: 0.5rem;
  line-height: 1.8;
  background-color: transparent;
  border: none;
  will-change: color;
  transition: color 0.2s ease;
  color: black;

  &::after,
  &::before {
    width: 0.75rem;
    height: 1px;
    top: 50%;
    left: auto;
    right: 0;
    background-color: black;
    will-change: background-color;
    transition: background-color 0.2s ease;
    transform: translateY(-50%);
  }

  &::after {
    transform: translateY(-50%) rotate(45deg);
  }

  &::before {
    transform: translateY(-50%) rotate(-45deg);
  }

  &:hover {
    color: rgba($color: black, $alpha: 1);

    &::after,
    &::before {
      background-color: black;
    }
  }
}

.addInsuranceBtn {
  position: absolute;
  padding-right: 1rem;
  bottom: -1.5rem;
  right: 0;
  cursor: pointer;
  font-size: 0.5rem;
  line-height: 1.8;
  background-color: transparent;
  border: none;
  will-change: color;
  transition: color 0.2s ease;
  color: black;

  &:hover {
    color: rgba($color: #626262, $alpha: 1);

    &::after,
    &::before {
      background-color: #626262;
    }
  }
}

.deleteInsuranceBtn {
  position: absolute;
  padding-right: 1rem;
  bottom: -1.5rem;
  right: 0;
  cursor: pointer;
  font-size: 0.5rem;
  line-height: 1.8;
  background-color: transparent;
  border: none;
  will-change: color;
  transition: color 0.2s ease;
  color: black;

  &::after,
  &::before {
    width: 0.75rem;
    height: 1px;
    top: 50%;
    left: auto;
    right: 0;
    color: black;
    background-color: black;
    will-change: black;
    transition: black 0.2s ease;
    transform: translateY(-50%);
  }

  &::after {
    transform: translateY(-50%) rotate(45deg);
  }

  &::before {
    transform: translateY(-50%) rotate(-45deg);
  }

  &:hover {
    color: rgba($color: #626262, $alpha: 1);

    &::after,
    &::before {
      background-color: #626262;
    }
  }
}

.promoCodeBtnWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.inputLogo,
.checkLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  pointer-events: none;
}

.checkLogo {
  left: auto;
  right: 6rem;
}

// ERROR FIELD
.promoCodeBtnWrapError {
  & .promoCodeField {
    border-color: #855757;
  }

  & .inputLogo {
    path {
      fill: #ca3c3c;
    }
  }
}

.promoCodeBtnWrapCheck {
  & .promoCodeField {
    border-color: #578561;
  }

  & .inputLogo {
    path {
      fill: #80e396;
    }
  }
}

.promoCodeBtn {
  width: 5.125rem;
  padding: 0;
  min-height: 2.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.166;
  background: none;
  color: black;
  border: none;
  cursor: pointer;
  transition: opacity 0.25s ease;
  outline: none;

  &:hover {
    opacity: 0.6;
  }
}

.promoCodeComplete {
  width: 20.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  margin-right: 1.875rem;
}

.message {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  color: #c86363;
}

@media (max-width: 1400px) {
  /* .costWrap {
    width: 20%;
  } */
}

@media (max-width: 1200px) {
  .notesWrapper {
    margin-top: 1rem;
    margin-bottom: 0;
  }

  .addBlock {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-right: 0;
  }

  .item {
    &:last-child {
      border-bottom: none;
    }
  }

  .infoBlockTitle {
    margin-right: 0px;
    margin-bottom: 1rem;
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .row {
    flex-wrap: wrap;
  }

  .textFile {
    max-width: none;
    width: 100%;

    min-width: 280px;
  }

  .cluster {
    max-width: none;
    width: 100%;

    min-width: 285px;
  }

  .labelFile,
  .labelFileBasket {
    margin-bottom: 2rem;
  }

  .infoBlock {
    width: 100%;
    border-left: none;
    padding-left: 0;
    margin-top: 1rem;
  }

  .text {
    max-width: 100px;
  }

  .deleteInsuranceBtn,
  .addInsuranceBtn {
    margin-right: 40px;
  }

  .logo {
    width: 50%;
  }

  .location,
  .format {
    width: auto;
  }

  .insideItem {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(6rem, 1fr));
    padding-left: 0.5rem;

    & .count {
      margin-bottom: 0;
    }
  }

  .insideItem + .insideItem {
    margin-top: 2rem;
  }

  .insideItem > * {
    padding-top: 0;
  }

  .insideItem:last-child > * {
    padding-bottom: 0;
  }

  .location,
  .count {
    margin-right: 0;
  }

  .count {
    text-align: left;
  }

  .location + .count {
    margin-left: 0;
  }

  .location + .format {
    margin-right: 0;
  }

  .costWrapC {
    display: flex;
    flex-direction: column;
    border-left: none;
    text-align: left;
    width: auto;
    padding-left: 0;
  }

  .cost {
    margin-top: auto;
  }

  .deleteItemBtn {
    bottom: auto;
    top: 1.875rem;
  }

  .promoCodeBtnWrap {
    display: block;
  }

  .countText {
    justify-content: flex-start;
  }

  .promoCodeField {
    width: 100%;
  }

  .addBlock {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-right: 0;
  }

  .inputLogo,
  .checkLogo {
    top: 0.75rem;
    transform: translateY(0);
  }

  .checkLogo {
    right: 1.5rem;
  }

  .message {
    display: none;
  }
}
@media (max-width: 421px) {
  .mobileRow {
    flex-direction: column;
  }
}
.formWrap {
  display: block;
}
