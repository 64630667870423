.messagePage {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.message {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: 2rem;
}

.btn {
  width: 100%;
  max-width: 15rem;
}