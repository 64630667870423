.similarWrap {
  margin-bottom: 6.25rem;
}

.offersWrap {
  margin-bottom: 5.625rem;
}

.titleWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.875rem;
}

.buttons {
  display: flex;
  gap: 3.5rem;
  width: 100%;
}

.title {
  margin-bottom: 0.25rem;
}

.label {
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 1.188rem;
}

.subtitle {}

.link {

  position: relative;
  width: 14.3125rem;
  padding: 0.5rem 2rem;
  border: 1px solid white;
  border-radius: 0.625rem;

  &:hover {
    & .icon {
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.25rem;
  transition: transform 0.3s ease;
}

.linkActive {
  color: #000000;
  background-color: #ffffff;
}

.settingsLink {
  margin-left: auto;
}

.notExistBlock {
  margin-bottom: 7.5rem;
}

.notExistTitle {
  text-transform: unset;
  margin-bottom: 2.25rem;
}

.notExistLink {
  font-size: 0.75rem;
  max-width: 17.25rem;
  width: 100%;
}

.unregEvents {
  margin-bottom: 6rem;
}

.regEvents {
  margin-bottom: 5rem;
}

.unregEvents+.regEvents {
  padding-top: 3.5rem;
  border-top: 1px solid #151515;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.125rem 1.5rem;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  max-width: 50rem;
  width: 100%;
  background-color: #151515;
  border-radius: 0.625rem;
}

.popupText {
  margin-bottom: 1rem;
  max-width: 25rem;
}

.closePopupBtn {
  background-color: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;

}

.btnMain {}

.notFoundMessage {
  margin-bottom: 2rem;
}

@media(max-width: 1200px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width: 900px) {
  .notExistBlock {
    margin-bottom: 5rem;
  }
}


@media(max-width: 678px) {
  .offersWrap {
    margin-bottom: 1.625rem;
  }

  .titleWrap {
    display: block;
  }

  .buttons {
    gap: 1rem;
  }

  .link+.settingsLink {
    width: 4.125rem;
  }

  .link {
    margin-top: 1.5rem;
  }

  .icon {
    left: 1.6rem;
  }

  .cover {
    display: none;
  }

  .list {
    display: block;
  }

  .item {
    width: 100%;
    flex-basis: auto;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  .itemWrapper {
    width: auto;
  }

  .btn {
    max-width: 15rem;
  }

  .btnRegistered {
    background-position: 1.125rem, 0;
  }

  .notExistBlock {
    margin-bottom: 3rem;
  }
}