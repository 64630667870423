.wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input,
.select,
.textarea {
  font-size: 14px;
  padding: 0.75rem 1rem;
  border-radius: 0.625rem;
  outline: none;
  box-shadow: none;
  border: 1px solid #3d3d3d;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.checkboxWrap {
  margin-top: 12px;
}
.securityPayment {
  cursor: pointer;
}

.paymentLogotypes {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.securityP {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.paymentImg {
  height: 2.9rem;
}

.deliverySelect {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.activityItem {
  display: flex;
  justify-content: space-between;

  & + & {
    margin-top: 1.25rem;
  }
}

.asideItemTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.asideItem {
  padding: 1.875rem 1.875rem 2.5rem;
  border-bottom: 1px solid rgba(68, 68, 68, 0.18);
}

.error {
  border: 1px solid red;
}

.textarea {
  resize: none;
}

.textareaLabel,
.labelFile {
  margin-bottom: 0.5rem;
}

.textareaLabel {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  color: #000000;
  margin-bottom: 15px;
}

.labelFile {
  background-color: #dadada;
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;

  & span {
    display: block;
    margin-bottom: 1rem;
  }
}

.checkboxWrap {
  display: flex;
  align-items: center;

  & a {
    color: black;
  }
}

.costWrap {
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  font-weight: 600;
}

.labelCheckbox {
  cursor: pointer;
  position: relative;
  padding-left: 2rem;

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
  }

  &::after {
    width: 1.5rem;
    height: 1.5rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px solid grey;
    border-radius: 0.3125rem;
  }

  &::before {
    display: none;
    width: 1rem;
    height: 1rem;
    top: 50%;
    left: 0.75rem;
    background-color: #db4c4c;
    border-radius: 0.3125rem;
    transform: translate(-50%, -50%);
  }
}

.checkbox:checked + .labelCheckbox {
  &::before {
    display: block;
  }
}
@media (max-width: 980px) {
  .checkboxWrap {
    margin-top: 18px;
  }
}

@media (max-width: 768px) {
  .input,
  .select,
  .textarea {
    font-size: 12px;
  }
}
@media (max-width: 400px) {
  .checkboxWrap {
    margin-top: 12px;
  }
}
