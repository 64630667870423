@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro'), local('GothamPro'),
    url('./GothamPro.woff') format('woff'),
    url('./GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
    url('./GothamPro-Bold.woff') format('woff'),
    url('./GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
    url('./GothamPro-Medium.woff') format('woff'),
    url('./GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}