.itemLabel {
  display: block;
  width: 100%;
  font-size: 0.625rem;
  font-weight: 400;
}

.qr {
  width: 50px;
  height: 50px;

  position: absolute;
  top: 10%;
  cursor: pointer;

  & path {
    fill: #262424;
  }
}

.qrCodeWrapper {
  position: relative;
  padding: 1.563rem;
  display: flex;
  width: 80%;
  min-height: 16.75rem;
  background-color: rgb(248, 248, 248);
  border-radius: 0.938rem;
  z-index: 10;
  min-height: 300px;
}

.ticketCard {
  position: relative;
}

.qrTicketReg {
  width: 50px;
  height: 50px;

  position: absolute;
  top: 10%;
  right: 32%;
  cursor: pointer;

  & path {
    fill: #262424;
  }
}

.wrapper {
  padding: 1.563rem;
  display: flex;
  align-items: stretch;
  align-content: space-between;
  flex-wrap: wrap;
  width: 49.063rem;
  min-height: 16.75rem;
  background-color: rgb(248, 248, 248);
  border-radius: 0.938rem;
  position: relative;
  z-index: 2;
}

.main {
  width: 34.688rem;
}

.logo {
  margin-bottom: 2.188rem;
}

.description {
  max-width: 26.813rem;
  width: 100%;
  font-size: 0.625rem;
  word-break: break-all;
}

.terms {
  margin-bottom: 0.75rem;
  padding-top: 0.813rem;
  padding-left: 2.125rem;
  width: 10.5rem;
  border-left: 1px solid #282828;
}

.terms_title {
  margin-bottom: 1.438rem;
  font-size: 0.75rem;
}

.terms_item {
  font-size: 0.625rem;
  list-style: disc;
  min-width: 150px;
  max-width: 150px;

  &:not(:last-child) {
    margin-bottom: 0.563rem;
  }

  &::marker {
    color: #fe4f4f;
  }
}

.information {
  padding-top: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-top: 1px solid #282828;
}

.information_item {
  margin-right: 2.125rem;
  font-size: 0.875rem;
  font-weight: 700;
}

.information_itemNumber {
  margin-left: auto;
  margin-right: 0;
}

.infInsur {
  margin-right: 0;
}

.information_label {
  display: block;
  width: 100%;
  font-size: 0.625rem;
  font-weight: 400;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  z-index: 1;
  justify-content: flex-end;
  font-size: 0;
  border-radius: 0.938rem;
  width: 40%;
  height: 100%;
  background-image: linear-gradient(180deg, #db4c4c, #d89);
}

.coverImg {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: right;
  border-radius: inherit;
  mix-blend-mode: normal;
}

.isQrClick {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item_number {
  font-size: 0.875rem;
  font-weight: 700;
}

.informationTwo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

@media (max-width: 1440px) {
  .wrapper {
    width: 70%;
  }
}

@media (max-width: 1153px) {
  .qrTicketReg {
    right: 35%;
  }

  /*  .qr {
    right: 32%;
  } */

  .terms {
    border-left: unset;
  }
}

@media (max-width: 991px) {
  .qrCodeWrapper {
    width: 100%;
  }
  .main {
    width: 100%;
  }

  .terms {
    width: auto;
    border-left: none;
    padding-top: 2rem;
    padding-left: 0;
    margin-bottom: 1rem;
  }

  .terms_list {
    padding-left: 1.25rem;
    display: flex;
    flex-direction: column;
  }

  .terms_item {
    &:not(:last-child) {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }
  }

  .information {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .information_item {
    margin-right: 0;
  }

  .information_itemNumber {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .informationTwo {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .cover {
    display: none;
  }

  /* .qr, */
  .qrTicketReg {
    right: 8%;
  }

  .wrapper {
    width: 100%;
  }

  .logo {
    margin-bottom: 1.25rem;
  }

  .terms {
    padding-top: 1rem;
  }

  .terms_title {
    margin-bottom: 1rem;
  }

  .terms_list {
    display: block;
  }

  .information {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
